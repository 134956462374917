<form [formGroup]="formUpdate">
    <div class="col">
        <div class="row" style="margin-bottom: 10px;">
            <button type="button" class="btn active float-right" style="display: -webkit-inline-box;" data-toggle="button" aria-pressed="true"
                (click)="backClicked()">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="row">
            <mat-card-title-group>
                <mat-card-title>
                    <mat-icon>settings</mat-icon> 
                    <span>
                        Cambio de contraseña 
                    </span>
                </mat-card-title>
            </mat-card-title-group>
            <mat-card-content>
                <div class="row">
                    <mat-form-field>
                        <input [type]="hidePassword ? 'password' : 'text'" matInput placeholder="Contraseña"
                            formControlName="password" required>
                        <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off'
                            : 'visibility'}}</mat-icon>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field>
                        <input [type]="hideRepeatPassword ? 'password' : 'text'" matInput
                            placeholder="Repetir contraseña" formControlName="repeatPassword" required>
                        <mat-icon matSuffix (click)="hideRepeatPassword = !hideRepeatPassword">{{hideRepeatPassword
                            ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field>
                        <input [type]="hideNewPassword ? 'password' : 'text'" matInput
                            placeholder="Nueva contraseña" formControlName="newPassword" required>
                        <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword">{{hideNewPassword
                            ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                </div>
            </mat-card-content>
            <div class="row">
                <div class="col">
                    <div class="button">
                        <div class="row">
                            <div class="col" >
                                <button class="large" mat-raised-button color="secondary"
                                (click)="backClicked()" style="float: right;"><i class="fas fa-arrow-left"></i> Volver a la tienda 
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="button">
                        <div class="row">
                            <div class="col">
                                <button [disabled]="!formUpdate.valid" class="large" mat-raised-button color="warn"
                                (click)="update()">
                                    Finalizar <i class="fas fa-wrench"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>


