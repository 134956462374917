import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Filtro } from 'src/app/models/filtro';
import { Plantin } from 'src/app/models/producto';
import { MensajeroService } from 'src/app/services/mensajero.service';
import { ProductoService } from 'src/app/services/producto.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit {

  plantines : Plantin[];

  desde : number = null;
  hasta : number = null;
  nombre : string = ''; 

  constructor(private _formBuilder: FormBuilder,
    private productoService: ProductoService,
    private mensajeService: MensajeroService) { }

  ngOnInit(): void {

  }

  filtrar(){

    this.productoService.filtrarProducto(this.desde,this.hasta,localStorage.getItem("tienda"),this.nombre).subscribe((plantines:Plantin[]) => {
      this.plantines = plantines;
      this.mensajeService.enviarPlantines(this.plantines);
    }) 
    
  }

  limpiar(){

    this.desde = null;
    this.hasta = null;
    this.nombre = '';

    this.productoService.getProductos().subscribe((plantinesBase)=>{
      this.plantines = plantinesBase;
      this.mensajeService.enviarPlantines(this.plantines);
    });

  }

}
