<div class="login-div layout-dark">
    <div class="login-content">
        <div class="login-panel">
            <div class="login-panel-content">
                <div class="logo">
                    <img src="assets/header_logo_unrn_negro (2).svg" alt="Responsive image" class="img-fluid"
                        style="height: 30px;">
                </div>
                <h1>Tienda Virtual de la Universidad Nacional de Río Negro</h1>
                <h3>
                    Ingrese su email y se le enviará un correo para reestablecer su contraseña
                </h3>
                <form [formGroup]="formRestore">
                    <div>
                        <mat-form-field>
                            <input type="email" matInput placeholder="Email" formControlName="email"
                                required>
                        </mat-form-field>
                    </div>
                    <div class="button">
                        <div class="row">
                            <div class="col">
                                <button [disabled]="formRestore.invalid" class="large" type="submit" mat-raised-button
                                    color="warn" (click)="restore()">Enviar <i class="fas fa-sign-in-alt"></i> </button>
                            </div>
                        </div>
                    </div>
                </form>
                <mat-divider style="margin-top: 30px; margin-bottom: 20px;"></mat-divider>

                <div class="row">
                    <div class="col">
                        <button class="large" mat-raised-button color="secondary"
                        (click)="goToLogin()"><i class="fas fa-arrow-left"></i> Volver 
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
