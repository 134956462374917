import { Plantin } from "./producto";

export class ItemCarrito {
  amount: number;
  id: number;
  saleDTO: Plantin;
  quantity : number;
  stock: number;
  
    constructor(id: number, saleDTO: Plantin, amount: number, quantity: number, stock: number) {
      this.id = null;
      this.saleDTO = saleDTO;
      this.amount = amount;
      this.quantity = quantity;
      this.stock = stock;
    }
}
