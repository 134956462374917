<div class="container" style="margin-top: 133px;">

    <div class="row">

        <div class="container px-4 px-lg-5 h-100">
            <div class="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                <div class="col-lg-8 align-self-end">
                    <h3 class="text-white-75 font-weight-bold">¡Bienvenido!</h3>
                    <hr class="divider" />
                </div>
                <div class="col-lg-8 align-self-baseline">
                    <h6 class="text-white-75 mb-5">
                        Usted se encuentra en el centro de compras del <strong>{{tienda}}</strong> 
                        <br>
                        
                        <!-- <a target="_blank">Si aquí no encuentra lo que desea, puede dirigirse hacia otro centro de compras desde el apartado <strong>TIENDAS</strong></a> -->
                    </h6>
                </div>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col">
            <app-filters></app-filters>
        </div>
        <div class="col-md-10">
            <app-lista-productos></app-lista-productos>
        </div>
    </div>
    <div class="row">
        <app-footer></app-footer>
    </div>

</div> 

