<mat-sidenav-container class="example-container" (backdropClick)="close()">
  <mat-sidenav class="sidenav" #sidenav (keydown.escape)="close()" position="end">
    <app-carrito></app-carrito>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="navbar navbar-white bg-white shadow-sm" style="margin-bottom: 50px;">
      <div class="container">
        <header class="navbar" [ngClass]="{'navbar-fixed': isFixedNavbar}">
          <div class="container" fxLayout="row wrap" fxLayoutAlign="start center">
            <div class="navbar-mobile-top" fxLayout="row" fxLayoutWrap="wrap" fxLayoutAlign="start center">
              <div class="row">
                <div class="col">
                  <div class="navbar-logo">
                    <app-header></app-header>
                  </div>
                </div>
                <button mat-icon-button color="warn" class="navbar-toggle" (click)="toggleNavbar()">
                  <mat-icon>{{!navbarOpened ? 'menu': 'close'}}</mat-icon>
                </button>
              </div>
            </div>
            <div fxFlex class="top-navbar">
              <span fxFlex></span>
              <div class="row">
                <div class="col">
                  <div *ngIf="tienda !== null">
                    <button mat-button style="color: black;" (click)="verDatosContacto()">
                      <i class="fas fa-comments" style="font-size: large; vertical-align: bottom;"></i>
                      <p style="margin-bottom: 0;">CONTACTO</p>
                    </button>
                  </div>
                </div>
                <div class="col">
                  <div>
                    <button mat-button (click)="sidenav.open()" style="color: black;" (click)="toggleBadgeVisibility()">
                      <i class="fas fa-shopping-cart" style="font-size: large; vertical-align: bottom;" matBadge="!"
                        matBadgeColor="warn" [matBadgeHidden]="hidden"></i>
                      <p style="margin-bottom: 0;">CARRITO</p>
                    </button>
                  </div>
                </div>
                <div class="col">
                  <div *ngIf="user !== null">
                    <button mat-button style="color: black;" [matMenuTriggerFor]="menu">
                      <i class="fas fa-user" style="font-size: large; vertical-align: bottom;"></i>
                      <p style="margin-bottom: 0;">{{user.firstName | uppercase}} {{user.lastName | uppercase}} <i
                          class="fas fa-caret-down"></i></p>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="changePassword()">
                        <mat-icon>settings</mat-icon>
                        <span>CAMBIAR CONTRASEÑA</span>
                      </button>
                      <button mat-menu-item (click)="logout()">
                        <mat-icon>logout</mat-icon>
                        <span>CERRAR SESION</span>
                      </button>
                    </mat-menu>
                  </div>
                </div>
                <div class="col">
                  <div *ngIf="user === null">
                    <button mat-button style="color: black;" (click)="login()">
                      <i class="fas fa-sign-in-alt" style="font-size: large; vertical-align: bottom;"></i>
                      <p style="margin-bottom: 0;">INICIAR SESION</p>
                    </button>
                  </div>
                </div>
              </div>
             
              <!--<button mat-button style="color: black;" [matMenuTriggerFor]="menuA">
                <i class="fas fa-landmark" style="font-size: large; vertical-align: bottom;"></i>
                <p style="margin-bottom: 0;">TIENDAS <i class="fas fa-caret-down"></i></p>
                <mat-menu #menuA="matMenu" xPosition="before">
                  <button mat-menu-item [matMenuTriggerFor]="viveros">
                    <mat-icon>local_mall</mat-icon>
                    <span>VIVEROS</span>
                  </button>
                  <button mat-menu-item disabled>
                    <mat-icon>local_mall</mat-icon>
                    <span>CURSOS (PROXIMAMENTE)</span>
                  </button>
                  <button mat-menu-item disabled>
                    <mat-icon>local_mall</mat-icon>
                    <span>LIBROS (PROXIMAMENTE)</span>
                  </button> 
                  <mat-menu #viveros>
                    <button mat-menu-item (click)="goToVivero2()">
                      <mat-icon>local_florist</mat-icon>
                      <span>VIVERO PASAJE GUTIERREZ</span>
                    </button>
                    <button mat-menu-item disabled>
                      <mat-icon>local_florist</mat-icon>
                      <span>VIVERO KM N°11 (PROXIMAMENTE)</span>
                    </button>
                  </mat-menu>
                </mat-menu>
              </button>-->
             
              
              
            </div>
          </div>
        </header>

      </div>
    </div>

    <div>
      <app-tienda-virtual></app-tienda-virtual>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>