<div class="card filters">
    <div class="card-header">
        <strong>Carrito</strong>
    </div>

    <div class="card-body">

        <div class="alert alert-info no-producto" style="text-align: center;" *ngIf="plantinesAComprar.length === 0">
            NO HAY PRODUCTOS EN EL CARRITO
            <i class="far fa-frown"></i>
            <br>
            <small *ngIf="user === null" >Recuerde que para poder comprar necesita iniciar sesión</small>
        </div>

        <ul class="list-group" *ngIf="plantinesAComprar.length > 0">
            <!-- <small style="text-align: center;" *ngIf="user === null">Recuerde que para poder comprar necesita <b>Iniciar Sesión</b></small> -->
            <li class="list-group-item total">
                <table>
                    <thead style="text-align: center;">
                        <tr>
                            <th style="width: 30%;">Nombre</th>
                            <th>Cantidad</th>
                            <th>Precio unitario</th>
                            <th>Total</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody style="text-align: center;">
                        <tr *ngFor="let producto of plantinesAComprar">
                            <td>{{producto.saleDTO.name}}</td>
                            <td>
                                <button type="button" class="btn btn-light" (click)="aumentarCantidad(producto.saleDTO.id, producto.quantity)"><i class="fas fa-plus"></i></button>
                                <form [formGroup]="cantidades">
                                    <mat-form-field appearance="legacy" style="width: 30px; text-align: center;">
                                      <input matInput formControlName="quantity" [(ngModel)]="quantity" value="{{producto.quantity}}" (ngModelChange)="verificarStock(producto.saleDTO.id)">
                                    </mat-form-field>
                                </form>
                                <button type="button" class="btn btn-light" (click)="reducirCantidad(producto.saleDTO.id, producto.quantity)"><i class="fas fa-minus"></i></button>
                            </td>
                            <td>{{ producto.amount | currency:'USD'}}</td>
                            <td>{{ (producto.amount * producto.quantity) | currency:'USD' }}</td>
                            <td> <button type="button" class="btn active float-right" data-toggle="button" 
                                aria-pressed="true" (click)="eliminarDelCarrito(producto.saleDTO.id,(producto.amount * producto.quantity))" >
                                <i class="fas fa-trash-alt"></i>
                            </button></td>
                        </tr>
                    </tbody>
                </table>
            </li>
            <li class="list-group-item total">
                <strong>Total:</strong> {{ precioTotal | currency:'USD' }}
            </li>
            <li class="list-group-item button">
                <button (click)="enviarCompra()" [disabled]="cantidades.invalid" class="btn btn-primary btn-block product-btn"
                    style="margin-top: 10px; width: 100%;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        viewBox="0 0 16 16" class="bi bi-cart-check">
                        <path
                            d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z">
                        </path>
                        <path
                            d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                        </path>
                    </svg> Comprar
                </button>
                <button (click)="limpiar()" class="btn btn-outline-secondary btn-block btn-sm"
                    style="margin-top: 10px; width: 100%;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        viewBox="0 0 16 16" class="bi bi-cart-dash">
                        <path d="M6.5 7a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4z"></path>
                        <path
                            d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
                        </path>
                    </svg> Limpiar
                </button>
            </li>
        </ul>

    </div>
</div>