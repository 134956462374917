import { Component, HostBinding, HostListener, OnInit , ViewChild} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {MatSidenav} from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import UserLogged from 'src/app/models/userLogged';
import { AuthService } from 'src/app/services/auth.service';
import { MensajeroService } from 'src/app/services/mensajero.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2'
import { ContactComponent } from './contact/contact.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  isFixedNavbar;
  @HostBinding('class.navbar-opened') navbarOpened = false;

  hidden = true;
  user : UserLogged
  tienda : string = null

  @ViewChild('sidenav') sidenav: MatSidenav;
  
  close() {
    this.sidenav.close();
  }

  constructor(private msj: MensajeroService,
    private singUpService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute) { }

  ngOnInit(): void { 

    this.msj.getMsj().subscribe(noti =>{
      this.hidden = false;
    })
    
    localStorage.setItem("tienda","Vivero_2");
    this.tienda = localStorage.getItem("tienda");
    this.user = this.singUpService.getUserValue();
    if(this.singUpService.getUserValue !== null ){
      this.singUpService.setUserIn(this.singUpService.getToken())
      this.user = this.singUpService.getUserValue()
    }

  }

  toggleBadgeVisibility(){
    this.hidden = true;
  }

  logout(){
    Swal.fire({
      title: '¿Está seguro?',
      text: "Esta a punto de salir de la tienda",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, salir!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.singUpService.logout()
      }
    })
  }

  goToVivero2(){
    localStorage.setItem("tienda","Vivero_2");
    this.tienda = localStorage.getItem("tienda");
  } 

  login(){
    this.router.navigate(['login'])
  }

  changePassword(){
    const dialogRef: MatDialogRef<any> = this.dialog.open(UpdatePasswordComponent, {
      width: '700px',
      disableClose: true,
      data: this.tienda
    });
    dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return
        }
    })
  }

   verDatosContacto(){
    const dialogRef: MatDialogRef<any> = this.dialog.open(ContactComponent, {
      width: '1000px',
      disableClose: true,
      data: this.tienda
    });
    dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return
        }
    })
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if(offset > 10) {
      this.isFixedNavbar = true;
    } else {
      this.isFixedNavbar = false;
    }
  }

  toggleNavbar() {
    this.navbarOpened = !this.navbarOpened;
  }
  
}
