<div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3" *ngIf="cantidadDePlantines > 0">
    <div class="col" *ngFor="let plantin of plantines">
        <app-producto-venta [item]="plantin"></app-producto-venta>
    </div>
</div>

<div class="row" *ngIf="cantidadDePlantines === 0">
    <div class="col">
        <div class="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
            <p class="text-white-75 mb-5">No existen productos con esas características!</p>
        </div>
    </div>
</div>