<div class="col">
    <div class="row" style="margin-bottom: 10px;">
        <button type="button" class="btn float-right" style="display: -webkit-inline-box;width: auto;" data-toggle="button" aria-pressed="true"
            (click)="backClicked()">
            <i class="fas fa-times"></i>
        </button>
    </div>
    <div class="row">
        <div class="row" style="height: 550px">
            <mat-card-title-group>
                <mat-card-title>
                    <mat-icon>local_mall</mat-icon> 
                    <span>
                        {{ tienda }} 
                    </span>
                </mat-card-title>
            </mat-card-title-group>
            <mat-card-content>
                <div>
                    <img src="../../../../../assets/image.png"
                        alt="imagen dirección pje gutierrez" width="75%" style="float: right;padding-left: 5%">
                </div>
                <div>
                    <br>
                    <span>
                        <strong>Ventas por tienda:</strong>
                        <p>
                            Desde miércoles 2 de octubre hasta el martes 15 de octubre inclusive
                        </p>
                    </span>
                    <span>
                        <strong>Entrega de plantas:</strong>
                        <p>
                            Vivero Educativo de la Tecnicatura en Viveros.
                        </p>
                    </span>
                    <span>
                        <strong>Direccion:</strong>
                        <p>
                            Calle Pasaje Gutiérrez N° 983 - San Carlos de Bariloche.
                        </p>
                    </span>
                    <span>
                        <strong>Entrega:</strong>
                        <p>
                            Entrega de plantas: Miércoles 16 de octubre de 11.00hs a 16.00hs  
                            <br>
                        </p>
                    </span>
                    <span>
                        <strong>Contacto:</strong>
                        <p>
                            <u>feriasplantastevi@gmail.com</u>
                        </p>
                    </span>
                </div>
            </mat-card-content>
        </div>
    </div>
</div>