import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Plantin } from 'src/app/models/producto';
import UserLogged from 'src/app/models/userLogged';
import { AuthService } from 'src/app/services/auth.service';
import { ProductoService } from 'src/app/services/producto.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tienda-virtual',
  templateUrl: './tienda-virtual.component.html',
  styleUrls: ['./tienda-virtual.component.css']
})
export class TiendaVirtualComponent implements OnInit {
  
  user: UserLogged 
  tienda : string

  constructor(private productoService: ProductoService,
    private singUpService: AuthService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.user = this.singUpService.getUserValue() 
    this.tienda = localStorage.getItem("tienda")
    if(this.tienda === "Vivero_2" ){
      this.tienda = "Vivero Pasaje Gutierrez"
    }else{
      if(this.tienda === "Vivero_1"){
        this.tienda = "Vivero KM N°11"
      }else{
        this.tienda = null
      }
    } 
    if(this.singUpService.getUserValue !== null ){
      this.singUpService.setUserIn(this.singUpService.getToken())
      this.user = this.singUpService.getUserValue()
    }
  }

}
