
    <div id="notfound">
        <div class="notfound">
            <div class="notfound-404">
                <h2>:(</h2>
            </div>
            <h2>404 - Pagina no encontrada</h2>
            <p>La página a la que está queriendo acceder es inalcanzable o no existe
            </p>
            <button (click)="goToLogin()">
                Go to HOME
            </button>
        </div>
    </div>

