<div class="card filters">
    <div  class="card-header">
        <strong >Filtros</strong>
    </div>
    <div class="card-body">
        <form >
            <div class="form-group">
                <label for="desde">Precio desde</label>
                <input type="number" [(ngModel)]="desde" [ngModelOptions]="{standalone: true}" class="form-control">
            </div>
            <div class="form-group">
                <label for="hasta">Precio hasta</label>
                <input type="number" [(ngModel)]="hasta" [ngModelOptions]="{standalone: true}" class="form-control">
            </div>
            <div class="form-group">
                <label for="hasta">Nombre</label>
                <input type="string" [(ngModel)]="nombre" [ngModelOptions]="{standalone: true}"  class="form-control" >
            </div>
            <button (click)="filtrar()" class="btn btn-primary btn-block product-btn" style="margin-top: 10px; width: 100%;">
                Filtrar <i class="fas fa-filter"></i>
            </button>
            <button (click)="limpiar()" class="btn btn-primary btn-block product-btn" style="margin-top: 10px; width: 100%;">
                Limpiar <i class="fas fa-eraser"></i>
            </button>
        </form>
    </div>
</div>

