import { Component, Input, OnInit } from '@angular/core';
import { Plantin } from 'src/app/models/producto';
import { MensajeroService } from 'src/app/services/mensajero.service';
import { ProductoService } from 'src/app/services/producto.service';

@Component({
  selector: 'app-lista-productos',
  templateUrl: './lista-productos.component.html',
  styleUrls: ['./lista-productos.component.css']
})
export class ListaProductosComponent implements OnInit {

  plantines : Plantin[];
  cantidadDePlantines = 0;

  constructor( private mensajeService: MensajeroService,
    private productoService: ProductoService) { }

  ngOnInit(): void {

    this.productoService.getProductos().subscribe((plantinesBase)=>{
      this.plantines = plantinesBase;
      this.cantidadDePlantines = this.plantines.length
    });

    this.mensajeService.enviarPlantinesObservable.subscribe(plantines => {
      this.plantines = plantines;
      this.cantidadDePlantines = this.plantines.length
    })

   
  }

}
