import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Plantin } from '../models/producto';
import { productosUrl, unProductoStockUrl, unProductoUrl, aplicarFiltro } from '../config/api';
import { Filtro } from '../models/filtro';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  
  constructor(private http: HttpClient,
    private authService: AuthService) { }

  getProductos(): Observable<any>{

    const headers = { 'Content-Type': 'application/json; charset=utf-8',
    'Authorization': 'Bearer ' + this.authService.getToken()};

    return this.http.get<Plantin[]>(productosUrl + localStorage.getItem("tienda"),{headers});
  }

  getPlantin(id: number): Observable<any>{
    const headers = {'Content-Type': 'application/json; charset=utf-8',
    'Authorization': 'Bearer ' + this.authService.getToken()};
    return this.http.get<Plantin>(unProductoUrl + id,{headers});
  }

  getStockPlantin (id: number): Observable<any>{
    const headers = {'Content-Type': 'application/json; charset=utf-8',
    'Authorization': 'Bearer ' + this.authService.getToken()};
    return this.http.get<Plantin>(unProductoStockUrl + id, {headers});
  }

  filtrarProducto(desde: number, hasta: number,vivero: string, nombre: string){
    const headers = {'Content-Type': 'application/json; charset=utf-8',
    'Authorization': 'Bearer ' + this.authService.getToken()};
    let filtro = new Filtro(desde,hasta,vivero,nombre);
    return this.http.post<Plantin[]>(aplicarFiltro, filtro,{headers})
  }
  

}

